import React, { useState } from "react";
import { useCookies } from "react-cookie";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { navigate } from "@reach/router";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItem } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from "@mui/material";

const MainHistory = props => {
  const type = props.type ? props.type : "lessons";
  const cookieExpiration = '2030-11-11 23:59:59'
  const cookieDate = new Date(cookieExpiration)

  const [cookies, setCookie, removeCookie] = useCookies([type]);
  console.log(cookies[type]);
  const initial = (cookies[type]) ? cookies[type] : [];
  const [newCookie, setNewCookie] = useState(initial);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusIndex, setFoucsIndex] = useState(null);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const deleteCookie = () => {
    let hogeCookie = newCookie;
    delete(hogeCookie[focusIndex]);
    hogeCookie = hogeCookie.filter(Boolean);
    console.log(hogeCookie);
    setNewCookie(hogeCookie);
    setCookie(type, hogeCookie, { expires: cookieDate, path: '/' });
  };

  const handleClose = (isDeleteInput) => {
    setDialogOpen(false);
    if (isDeleteInput) {
      deleteCookie();
    }
  };

  const handleDelete = (index) => {
    handleClickOpen();
    setFoucsIndex(index);
  };
  
  console.log(newCookie);

  const DeleteAction = props => {
    return (
      <IconButton edge="end" aria-label="削除" 
        size="large" sx={{mx: .5}}
        onClick={() => handleDelete(props.value)}
      >
        <DeleteIcon />
      </IconButton>
    )
  }
  

  return (
    <List sx={{ width: '100%', px: 0, mb: 4}} >

      {newCookie.map((value, index) => {
        return(
        <React.Fragment key={index + 214}>
          <ListItem
            key={value}
            sx={{p: '0 !important'}}
            secondaryAction={<DeleteAction value={index} />}       

          >

          <ListItemButton 
            onClick={() => {
              console.log(value)
              value.location = "/info/G1B20009?y=2020"
              const url = new URL(`https://rakutan-alps.com${value.location}`);
              const params = new URLSearchParams(url.search);
              const year = params.get("y");
              if (year) {
                const code = url.pathname.split('/').pop();
                navigate(`/info/${code}:${year}`)

              } else {
                navigate(value.location)

              }
            }}
          >
              <ListItemIcon sx={{pl: 1}}>
                {props.icon}
              </ListItemIcon>

            <ListItemText primary={value.name} secondary={value.teacher}/>

          </ListItemButton>


          </ListItem>


          <Dialog
            open={dialogOpen}
            onClose={handleClose}
          >
            <DialogTitle>
              削除しますか？
            </DialogTitle>
            
            <DialogContent sx={{minWidth: 300}}>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => handleClose(false)}>キャンセル</Button>
              <Button onClick={() => handleClose(true)} autoFocus>
                削除する
              </Button>
            </DialogActions>
          </Dialog>
            
        </React.Fragment>
        )
      })}

    </List>
  )

}


export default MainHistory;