import React, { useEffect } from "react";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { MainHistory } from ".";
import { useQuery } from "function/common";
import { useLocation } from "@reach/router";
import HistoryIcon from '@mui/icons-material/History';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';


export default function () {
  const query = useQuery();
  let initialValue = query.get("v");
  if (initialValue !== "1" && initialValue !== "2") initialValue = "1";
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [useLocation()])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(value);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
          <TabList onChange={handleChange} centered >
            <Tab label="履歴" value="1" icon={<HistoryIcon fontSize="small"/>} iconPosition="start" sx={{width: "50%"}} />
            <Tab label="ブックマーク" value="2" icon={<BookmarkIcon fontSize="small"/>} iconPosition="start" sx={{width: "50%"}} />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{p: 0}}>
          <MainHistory icon={<HistoryIcon color="secondary" />} />
        </TabPanel>

        <TabPanel value="2" sx={{p: 0}}>
          <MainHistory type="bookmark" icon={<BookmarkIcon color="secondary" />}/>
        </TabPanel>

      </TabContext>
    </Box>
  );
}